import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import FeedbackOrderStoreType from "@/views/Express/FeedbackOrder/Store/indexType";
import {UpDataType} from "@/Api/Express/interfaceType";

@Module({
    name:"FeedbackOrder",
    store,
    dynamic: true,
})
export default class FeedbackOrder extends VuexModule implements FeedbackOrderStoreType{
    FeedbackOrderList: any[] = [];
    get getFeedbackOrderList(){ return this.FeedbackOrderList }
    @Mutation
    SetFeedbackOrderList(data: any[]): void {
        this.FeedbackOrderList = data
    }

    FeedbackOrderUpData: UpDataType={
        userId:"",
        pageNo: 1,
        pageSize: 15,
    };
    get getFeedbackOrderUpData(){ return this.FeedbackOrderUpData }
    @Mutation
    SetFeedbackOrderUpData(data: UpDataType): void {
        this.FeedbackOrderUpData = data
    }

    FeedbackOrderClear = false;
    get getFeedbackOrderClear(){return this.FeedbackOrderClear}
    @Mutation
    SetFeedbackOrderClear(data: boolean) {
        if ( data ){
            let time = setTimeout(()=>{
                this.FeedbackOrderClear = false
                clearTimeout(time)
            },3000)
        }
        this.FeedbackOrderClear = data
    }

}

export const FeedbackOrderStore = getModule( FeedbackOrder )
