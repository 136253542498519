

export const ZoomList = [
    {
        title:"更新时间",
        state:"update_time"
    },{
        title:"创建时间",
        state:"create_time"
    },{
        title:"取货码",
        state:"mail_code"
    },{
        title:"收件人",
        state:"name"
    },{
        title:"手机号",
        state:"phone"
    },{
        title:"序号",
        state:"sequence"
    }
]

export const OrderStatus = [
    {
        title:"未支付",
        state:"0"
    },{
        title:"无人接单",
        state:"1"
    },{
        title:"已接单",
        state:"2"
    },{
        title:"待确认",
        state:"3"
    },{
        title:"已完成",
        state:"4"
    },{
        title:"订单异常",
        state:"5"
    },{
        title:"已取消",
        state:"6"
    },{
        title:"已删除",
        state:"7"
    },
]
