import Storage from "@/util/Storage"
import {FindExpress, GetFrom} from "@/Api/Express";
import {FilterPullItem} from "@/util/filter";
import {StoreExpress} from "@/store/modules/Express/Express";
import {FeedbackOrderStore} from "@/views/Express/FeedbackOrder/Store";

export function getFromList() {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(GetFrom({ userId,token }).then(res=>{
        StoreExpress.SetFromList(res.data)
        return res.data && FilterPullItem(res.data,"name") || []
    }))
}

// 搜索异常订单
export function getFindExpress() {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(FindExpress({ userId,token },FeedbackOrderStore.getFeedbackOrderUpData).then(res=>{
        return res.data || []
    }))
}
